import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233'),
	() => import('./nodes/234'),
	() => import('./nodes/235'),
	() => import('./nodes/236'),
	() => import('./nodes/237'),
	() => import('./nodes/238'),
	() => import('./nodes/239'),
	() => import('./nodes/240'),
	() => import('./nodes/241')
];

export const server_loads = [10,5];

export const dictionary = {
		"/(public)": [30,[8],[9]],
		"/(public)/about-indow": [43,[8],[9]],
		"/(public)/about-indow/careers": [48,[8],[9]],
		"/(public)/about-indow/care": [44,[8],[9]],
		"/(public)/about-indow/care/installation-resources": [45,[8],[9]],
		"/(public)/about-indow/care/measuring-diagonals": [46,[8],[9]],
		"/(public)/about-indow/care/safety-hardware": [47,[8],[9]],
		"/(public)/about-indow/contact": [49,[8],[9]],
		"/(public)/about-indow/contractor-discount-program": [50,[8],[9]],
		"/(public)/about-indow/how-to-order": [51,[8],[9]],
		"/(public)/about-indow/how-to-order/measure": [52,[8],[9]],
		"/(public)/about-indow/how-to-order/mullions": [53,[8],[9]],
		"/(public)/about-indow/management-team": [54,[8],[9]],
		"/(public)/about-indow/media": [55,[8],[9]],
		"/(public)/about-indow/media/media-list": [56,[8],[9]],
		"/(public)/better-than-storm-windows": [57,[8],[9]],
		"/(public)/bigfoot": [58,[8],[9]],
		"/(public)/(people)/brian-mccracken": [31,[8,10],[9]],
		"/(public)/claim-submission-customer": [59,[8],[9]],
		"/(public)/claim-submission-customer/received": [60,[8],[9]],
		"/(public)/clear-acoustic-panels": [61,[8],[9]],
		"/(empty)/colors": [12,[2]],
		"/(public)/commercial-storm-windows": [64,[8],[9]],
		"/(public)/commercial-storm-windows/historic-tax-credit": [65,[8],[9]],
		"/(public)/commercial-storm-windows/hotel-soundproofing": [66,[8],[9]],
		"/(public)/commercial-storm-windows/office-soundproofing": [67,[8],[9]],
		"/(public)/commercial-storm-windows/pilot-program": [68,[8],[9]],
		"/(public)/commercial-storm-windows/testimonials": [69,[8],[9]],
		"/(public)/commercial/indowpro-advanced-window-insulation-system": [62,[8],[9]],
		"/(public)/commercial/indowpro-dealer-program": [63,[8],[9]],
		"/(public)/contractor-agreement": [70,[8],[9]],
		"/(public)/custom-storm-windows": [71,[8],[9]],
		"/(public)/custom-storm-windows/acoustic-grade": [72,[8],[9]],
		"/(public)/custom-storm-windows/commercial-grade": [73,[8],[9]],
		"/(public)/custom-storm-windows/museum-grade": [74,[8],[9]],
		"/(public)/custom-storm-windows/privacy-grade": [75,[8],[9]],
		"/(public)/custom-storm-windows/sleep-panel": [76,[8],[9]],
		"/(public)/custom-storm-windows/standard-grade": [77,[8],[9]],
		"/(public)/(people)/eboni-washington": [32,[8,10],[9]],
		"/(public)/(people)/emily-jackson": [33,[8,10],[9]],
		"/(public)/energy-efficiency-comfort": [78,[8],[9]],
		"/(public)/energy-efficiency-fb": [79,[8],[9]],
		"/(public)/estimated-lead-times": [80,[8],[9]],
		"/(public)/(people)/fit-specialists": [34,[8,10],[9]],
		"/(public)/forms": [81],
		"/(public)/forms/claim-submission": [~82,[8,11],[9]],
		"/(public)/forms/contact-preference": [~83,[8,11],[9]],
		"/(public)/forms/contact-us": [~84,[8,11],[9]],
		"/(public)/forms/contractor-agreement": [~86,[8,11],[9]],
		"/(public)/forms/contractor": [~85,[8,11],[9]],
		"/(public)/forms/lead-commercial": [~88,[8,11],[9]],
		"/(public)/forms/lead": [~87,[8,11],[9]],
		"/(public)/forms/measure-support": [~89,[8,11],[9]],
		"/(public)/forms/postal-code-commercial": [~91],
		"/(public)/forms/postal-code-map": [~92],
		"/(public)/forms/postal-code": [~90],
		"/(public)/gallery": [93,[8],[9]],
		"/(public)/gallery/before-and-after": [94,[8],[9]],
		"/(public)/guides/how-to-install": [95,[8],[9]],
		"/(public)/guides/how-to-measure-for-estimates": [96,[8],[9]],
		"/(public)/guides/how-to-measure-for-mullions": [97,[8],[9]],
		"/(public)/guides/how-to-measure-with-a-laser": [98,[8],[9]],
		"/(public)/guides/how-to-measure-with-tape": [99,[8],[9]],
		"/(public)/guides/indow-one-product-specifications": [100,[8],[9]],
		"/(public)/guides/indow-window-compatibility": [101,[8],[9]],
		"/(public)/guides/laser-measurement-form": [102,[8],[9]],
		"/(public)/guides/receiving-your-inserts": [103,[8],[9]],
		"/(public)/guides/tape-measurement-form": [104,[8],[9]],
		"/(public)/guides/using-indow-with-blinds-shutters": [105,[8],[9]],
		"/(public)/(people)/hannah-hendrickson": [35,[8,10],[9]],
		"/(public)/holiday-gift-guide": [106,[8],[9]],
		"/(public)/ik-film": [107,[8],[9]],
		"/(public)/indow-cost-calc": [109,[8],[9]],
		"/(public)/indow-cost": [108,[8],[9]],
		"/(public)/instant-estimate": [110,[8],[9]],
		"/(public)/(people)/james-werth": [36,[8,10],[9]],
		"/(public)/(people)/kaya-carvey": [37,[8,10],[9]],
		"/(public)/(people)/martin-lewin": [38,[8,10],[9]],
		"/(public)/(people)/measure-support": [39,[8,10],[9]],
		"/(public)/modi-customer-measurements-submitted-iframe": [111,[8],[9]],
		"/(private)/my": [~17,[3,5]],
		"/(private)/my/calendar": [18,[3,5,6]],
		"/(private)/my/calendar/measure-support": [19,[3,5,6]],
		"/(private)/my/calendar/other": [20,[3,5,6]],
		"/(private)/my/dashboard": [~21,[3,5,7]],
		"/(private)/my/dashboard/estimate": [22,[3,5,7]],
		"/(private)/my/dashboard/measure": [23,[3,5,7]],
		"/(private)/my/dashboard/order": [24,[3,5,7]],
		"/(private)/my/dashboard/start": [~25,[3,5,7]],
		"/(private)/my/estimates/[id]": [~26,[3,5]],
		"/(private)/my/orders": [27,[3,5]],
		"/(private)/my/orders/[id]": [~28,[3,5]],
		"/(private)/my/profile": [~29,[3,5]],
		"/(public)/noise-disclaimer": [112,[8],[9]],
		"/(public)/performance": [113,[8],[9]],
		"/(public)/pricing": [114,[8],[9]],
		"/(public)/privacy-policy": [115,[8],[9]],
		"/(public)/production-photo": [116,[8],[9]],
		"/(public)/redefining-comfort": [117,[8],[9]],
		"/(public)/(people)/reed-nicholson": [40,[8,10],[9]],
		"/(public)/resources": [118,[8],[9]],
		"/(public)/resources/blog/6-simple-ways-fix-drafty-windows": [119,[8],[9]],
		"/(public)/resources/blog/about-local-law-97-nyc": [120,[8],[9]],
		"/(public)/resources/blog/acrylic-vs-glass": [121,[8],[9]],
		"/(public)/resources/blog/arched-windows": [122,[8],[9]],
		"/(public)/resources/blog/block-pickleball-noise": [123,[8],[9]],
		"/(public)/resources/blog/cold-air-windows": [124,[8],[9]],
		"/(public)/resources/blog/compression-seals-for-windows": [125,[8],[9]],
		"/(public)/resources/blog/custom-storm-windows-save-old-windows": [126,[8],[9]],
		"/(public)/resources/blog/diy-guide-to-soundproofing-your-own-windows": [127,[8],[9]],
		"/(public)/resources/blog/diy-soundproof-window-solution": [128,[8],[9]],
		"/(public)/resources/blog/diy-window-treatments": [129,[8],[9]],
		"/(public)/resources/blog/energy-efficient-window-myths": [130,[8],[9]],
		"/(public)/resources/blog/energy-tax-credits-window-inserts": [131,[8],[9]],
		"/(public)/resources/blog/five-easy-ways-to-winterize-your-windows": [132,[8],[9]],
		"/(public)/resources/blog/fixing-old-windows": [133,[8],[9]],
		"/(public)/resources/blog/how-to-block-sunlight-heat-from-windows": [134,[8],[9]],
		"/(public)/resources/blog/how-to-design-a-meditation-room": [135,[8],[9]],
		"/(public)/resources/blog/how-to-easily-seal-windows-for-the-winter-months": [136,[8],[9]],
		"/(public)/resources/blog/how-to-file-noise-complaint-online": [137,[8],[9]],
		"/(public)/resources/blog/how-to-fix-a-cold-room": [138,[8],[9]],
		"/(public)/resources/blog/how-to-soundproof-baby-room": [139,[8],[9]],
		"/(public)/resources/blog/how-to-soundproof-window-air-conditioner": [140,[8],[9]],
		"/(public)/resources/blog/improve-home-energy-score": [141,[8],[9]],
		"/(public)/resources/blog/increase-home-resale-value-with-inserts": [142,[8],[9]],
		"/(public)/resources/blog/indoor-air-quality-solutions": [143,[8],[9]],
		"/(public)/resources/blog/insulate-old-windows-without-replacing": [144,[8],[9]],
		"/(public)/resources/blog/insulate-specialty-windows": [145,[8],[9]],
		"/(public)/resources/blog/insulating-apartment-windows": [146,[8],[9]],
		"/(public)/resources/blog/keep-cool-save-energy-block-heat": [147,[8],[9]],
		"/(public)/resources/blog/magnetic-seal-vs-compression-seal": [148,[8],[9]],
		"/(public)/resources/blog/magnetic-storm-windows-pros-and-cons": [149,[8],[9]],
		"/(public)/resources/blog/most-effective-soundproofing-materials": [150,[8],[9]],
		"/(public)/resources/blog/muffle-dog-barking": [151,[8],[9]],
		"/(public)/resources/blog/neighbors-playing-loud-music": [152,[8],[9]],
		"/(public)/resources/blog/noise-complaint-nyc": [153,[8],[9]],
		"/(public)/resources/blog/noise-pollution-human-health": [154,[8],[9]],
		"/(public)/resources/blog/old-hardware": [155,[8],[9]],
		"/(public)/resources/blog/old-window-restoration": [156,[8],[9]],
		"/(public)/resources/blog/painted-shut-windows": [157,[8],[9]],
		"/(public)/resources/blog/performance-storm-windows-regular-windows": [158,[8],[9]],
		"/(public)/resources/blog/polar-vortex-preparation": [159,[8],[9]],
		"/(public)/resources/blog/r-value-vs-u-value": [160,[8],[9]],
		"/(public)/resources/blog/retrofit-attachment-vs-replacement": [161,[8],[9]],
		"/(public)/resources/blog/soundproof-apartment": [162,[8],[9]],
		"/(public)/resources/blog/soundproof-curtains": [163,[8],[9]],
		"/(public)/resources/blog/soundproof-home-office": [164,[8],[9]],
		"/(public)/resources/blog/soundproof-windows-cost": [165,[8],[9]],
		"/(public)/resources/blog/soundproof-windows-home-depot": [166,[8],[9]],
		"/(public)/resources/blog/storm-window-alternative": [167,[8],[9]],
		"/(public)/resources/blog/storm-window-cost": [168,[8],[9]],
		"/(public)/resources/blog/supply-chain-disruption-delays": [169,[8],[9]],
		"/(public)/resources/blog/thermal-windows": [170,[8],[9]],
		"/(public)/resources/blog/ways-to-save-with-energy-efficiency": [171,[8],[9]],
		"/(public)/resources/blog/what-is-a-frame-step": [172,[8],[9]],
		"/(public)/resources/blog/white-roofs-keep-house-cool": [173,[8],[9]],
		"/(public)/resources/blog/why-is-my-house-so-hot": [174,[8],[9]],
		"/(public)/resources/blog/why-we-laser-measure-windows": [175,[8],[9]],
		"/(public)/resources/blog/window-blackout-solution": [176,[8],[9]],
		"/(public)/resources/blog/window-condensation": [177,[8],[9]],
		"/(public)/resources/blog/window-insert-replacements": [178,[8],[9]],
		"/(public)/resources/blog/window-inserts-cut-for-compression": [179,[8],[9]],
		"/(public)/resources/case-studies": [180,[8],[9]],
		"/(public)/resources/case-studies/block-cold-from-windows": [181,[8],[9]],
		"/(public)/resources/case-studies/church-storm-windows": [182,[8],[9]],
		"/(public)/resources/case-studies/energy-efficiency-old-homes": [183,[8],[9]],
		"/(public)/resources/case-studies/insulate-double-pane-windows": [184,[8],[9]],
		"/(public)/resources/case-studies/office-soundproofing": [185,[8],[9]],
		"/(public)/resources/case-studies/soundproofing-windows-nyc": [186,[8],[9]],
		"/(public)/resources/case-studies/stop-window-condensation": [187,[8],[9]],
		"/(public)/resources/case-studies/street-noise-reduction": [188,[8],[9]],
		"/(public)/resources/case-studies/this-old-house-charleston": [189,[8],[9]],
		"/(public)/resources/case-studies/window-privacy-ideas": [190,[8],[9]],
		"/(public)/resources/case-studies/window-replacement-alternative": [191,[8],[9]],
		"/(public)/resources/customer-reviews": [192,[8],[9]],
		"/(public)/resources/faq": [193,[8],[9]],
		"/(public)/resources/faq/interior-storm-window-inserts-glossary": [194,[8],[9]],
		"/(public)/resources/featured-articles": [195,[8],[9]],
		"/(public)/resources/historic-preservation": [196,[8],[9]],
		"/(public)/resources/home-soundproofing": [197,[8],[9]],
		"/(public)/resources/indow-window-compatibility": [198,[8],[9]],
		"/(public)/resources/soundproof-room-music": [199,[8],[9]],
		"/(public)/resources/soundproofing-windows": [200,[8],[9]],
		"/(public)/resources/stc-rating": [201,[8],[9]],
		"/(public)/resources/storm-windows-guide/replacement": [202,[8],[9]],
		"/(public)/resources/storm-windows-guide/traditional": [203,[8],[9]],
		"/(public)/resources/tips-soundproofing-commercial-spaces": [204,[8],[9]],
		"/(public)/resources/window-guides": [205,[8],[9]],
		"/(public)/resources/window-guides/skylight-window-inserts": [206,[8],[9]],
		"/(public)/(people)/ryan-libby": [41,[8,10],[9]],
		"/(public)/sales/black-friday": [207,[8],[9]],
		"/(public)/sales/fathers-day": [208,[8],[9]],
		"/(public)/sales/independence-day": [209,[8],[9]],
		"/(public)/sales/labor-day": [210,[8],[9]],
		"/(public)/sales/last-chance-free-shipping": [211,[8],[9]],
		"/(public)/sales/march-madness": [212,[8],[9]],
		"/(public)/sales/memorial-day": [213,[8],[9]],
		"/(public)/self-measure-instructions": [214,[8],[9]],
		"/(private)/(auth)/signin": [~13,[3,4]],
		"/(private)/(auth)/signin/lead": [~14,[3,4]],
		"/(private)/(auth)/signout": [~15,[3,4]],
		"/(private)/(auth)/signup": [~16,[3,4]],
		"/(public)/site-map": [215,[8],[9]],
		"/(public)/solutions": [216,[8],[9]],
		"/(public)/solutions/comfort-performance": [217,[8],[9]],
		"/(public)/solutions/insulate-windows-from-heat": [218,[8],[9]],
		"/(public)/solutions/insulating-drafty-windows": [219,[8],[9]],
		"/(public)/solutions/light-uv": [220,[8],[9]],
		"/(public)/solutions/noise-performance": [221,[8],[9]],
		"/(public)/solutions/odd-shaped-window-inserts": [222,[8],[9]],
		"/(public)/solutions/window-noise-reduction": [223,[8],[9]],
		"/(public)/solutions/winter-window-insulation": [224,[8],[9]],
		"/(public)/storm-windows": [225,[8],[9]],
		"/(public)/test": [226,[8],[9]],
		"/(public)/thank-you-2": [227,[8],[9]],
		"/(public)/thermal-insulation-fb": [228,[8],[9]],
		"/(public)/(people)/tyler-johnson": [42,[8,10],[9]],
		"/(public)/warranties/commercial-warranty": [229,[8],[9]],
		"/(public)/warranties/expedited-production": [230,[8],[9]],
		"/(public)/warranties/quiet-comfort-guarantee": [231,[8],[9]],
		"/(public)/warranties/residential-lifetime-warranty": [232,[8],[9]],
		"/(public)/warranties/snug-fit-guarantee": [233,[8],[9]],
		"/(public)/warranties/terms-of-sale": [234,[8],[9]],
		"/(public)/welcome-1": [235,[8],[9]],
		"/(public)/welcome-cdp": [236,[8],[9]],
		"/(public)/window-insert-tax-savings": [237,[8],[9]],
		"/(public)/window-insulation-kit": [238,[8],[9]],
		"/(public)/window-of-opportunity": [239,[8],[9]],
		"/(public)/window-soundproofing-for-noise": [241,[8],[9]],
		"/(public)/window-soundproofing": [240,[8],[9]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';